export * from './shared.module';

export class CommonUtilities {

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  GetSecondsToMinute(inputSeconds: number) {
    const secs = inputSeconds;
    let nMinute = "";
    let nSeconds = "";

    if (secs > 0) {
      let minutes = Math.floor(secs / 60);
      let seconds = secs - minutes * 60;



      if (10 > minutes) {
        nMinute = '0' + minutes;
      } else {
        nMinute = minutes.toString();
      }

      if (10 > seconds) {
        nSeconds = '0' + seconds;
      } else {
        nSeconds = seconds.toString();
      }
    }

    if (nMinute || nSeconds) {
      return nMinute + ':' + nSeconds;
    } else {
      return '';
    }

  }

  humanFileSize(bytes, si = false, dp = 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }

    const units = si
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;

    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


    return bytes.toFixed(dp) + ' ' + units[u];
  }

  validateFile(name: String, allowedtypes: string[]) {
    let IsValid: boolean = false;
    var ext = name.substring(name.lastIndexOf('.') + 1);

    if (allowedtypes.length > 0) {
      let IsExists = allowedtypes.filter(x => x.toLowerCase() == ext.toLowerCase());

      if (IsExists && IsExists.length > 0) {
        IsValid = true;
      }
    }

    return IsValid;
  }

  humanReadableTime(seconds: number) {
    var numyears = Math.floor(seconds / 31536000);
    var numdays = Math.floor((seconds % 31536000) / 86400);
    var numhours = Math.floor(((seconds % 31536000) % 86400) / 3600);
    var numminutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60);
    var numseconds = (((seconds % 31536000) % 86400) % 3600) % 60;

    let rtrTime = numhours > 0 ? numhours + " hours " : "";
    rtrTime += numminutes > 0 ? numminutes + " minutes " : "";
    rtrTime += numseconds > 0 ? numseconds + " seconds" : "";
    return rtrTime;
  }

  humanReadableTime_minify(seconds: number) {
    var numyears = Math.floor(seconds / 31536000);
    var numdays = Math.floor((seconds % 31536000) / 86400);
    var numhours = Math.floor(((seconds % 31536000) % 86400) / 3600);
    var numminutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60);
    var numseconds = (((seconds % 31536000) % 86400) % 3600) % 60;

    let rtrTime = numhours > 0 ? numhours + " hrs " : "";
    rtrTime += numminutes > 0 ? numminutes + " min " : "";
    rtrTime += numseconds > 0 ? numseconds + " sec" : "";
    return rtrTime;
  }

  markFormat = [
    {
      id: 1,
      value: 'none'
    },
    {
      id: 2,
      value: '[ ]'
    }, {
      id: 3,
      value: '( )'
    }, {
      id: 4,
      value: '{ }'
    }];

  questionFormat = [
    {
      id: 1,
      value: '(i)'
    }, {
      id: 2,
      value: '(a)'
    }, {
      id: 3,
      value: '(1)'
    }, {
      id: 4,
      value: 'i'
    }, {
      id: 5,
      value: 'a'
    }, {
      id: 6,
      value: '1'
    }
  ];

  romanMatrix = [
    [1000, 'M'],
    [900, 'CM'],
    [500, 'D'],
    [400, 'CD'],
    [100, 'C'],
    [90, 'XC'],
    [50, 'L'],
    [40, 'XL'],
    [10, 'X'],
    [9, 'IX'],
    [5, 'V'],
    [4, 'IV'],
    [1, 'I']
  ];

  convertToRoman(num: number) {
    if (num === 0) {
      return '';
    }
    for (var i = 0; i < this.romanMatrix.length; i++) {
      if (num >= Number(this.romanMatrix[i][0])) {
        return this.romanMatrix[i][1] + this.convertToRoman(num - Number(this.romanMatrix[i][0]));
      }
    }
  }

}
