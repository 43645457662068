import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'content'
})

export class TextTrim implements PipeTransform {

  transform(val, arg) {
    if (val) {
      if (!arg) { return val; }
      if (val.length > arg) {
        return val.substring(0, arg) + '...';
      } else {
        return val;
      }
    } else {
      return val;
    }
  }

}
