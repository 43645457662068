<!-- <div style="display: flex;align-items: center;justify-content: center; padding: 40px;">
  <div style="display: grid;">
    <img src="/assets/Images/loader.gif" style="height: 100px;">
    <span>Please Wait...</span>
  </div>
</div> -->

<div class="loaderDiv">
  <mat-spinner [diameter]="45" class="appLoader"></mat-spinner>
</div>
