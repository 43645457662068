<div class="m-chart">
  <div *ngIf="type==='pie' || type==='doughnut'">
    <canvas baseChart
        [data]="data"
        [labels]="labels"
        [chartType]="type"
        [options]="pieChartOptions"
        [plugins]="pieChartPlugins"
        [colors]="type==='doughnut' ? donutColors : null"
        [legend]="ChartLegend"
        (chartClick)="chartClicked($event)">
      </canvas>
  </div>
</div>


<div class="m-chart-1">
  <div *ngIf="type==='bar'">
    <canvas baseChart
        [datasets]="data"
        [labels]="labels"
        [chartType]="type"
        [options]="pieChartOptions"
        [plugins]="pieChartPlugins"
        [legend]="ChartLegend"
        (chartClick)="chartClicked($event)">
      </canvas>
  </div>
</div>
