import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-ckeditor-description',
  templateUrl: './ckeditor-description.component.html',
  styleUrls: ['./ckeditor-description.component.css']
})
export class CkeditorDescriptionComponent implements OnChanges {

  @Input() data: string;
  @Output() datachange = new EventEmitter();

  ckeConfig: any;

  constructor() { }

  ngOnChanges(): void {
    this.ckeConfig = {
      allowedContent: false,
      extraPlugins: 'wordcount,autogrow,justify,magicline,horizontalrule',
      wordcount: {
        // Whether or not you want to show the Word Count
        showWordCount: true,
        // Whether or not you want to show the Char Count
        showCharCount: false,
        // Maximum allowed Word Count
        showParagraphs: false,
        maxWordCount: 500,
      },
      autoGrow_minHeight: 100,
      autoGrow_maxHeight: 120,
      toolbar: [
        { name: 'clipboard', items: ['Undo', 'Redo'] },
        { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat'] },
        { name: 'paragraph', items: ['NumberedList', 'BulletedList', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'] },
        { name: 'styles', items: ['Styles', 'Format', 'HorizontalRule'] },
        { name: 'colors', items: ['TextColor', 'BGColor'] }
      ],
      removeButtons: 'Font,FontSize,Anchor,Flash,Source,Form,Save,Checkbox,Radio'
    };
  }

  onTextChange() {
    this.datachange.emit(this.data);
  }

}
