import { Component } from '@angular/core';
import { Event, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { IAuditLog } from './models/IResultInfo.model';
import { AuthService } from './services/auth.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'app';

  constructor(
    private router: Router,
    private _authService: AuthService
  ) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        // Show loading indicator
      }

      if (event instanceof NavigationEnd) {
        if (event.urlAfterRedirects != '/login') {
          // Call Activity log api for Event Redirect

          let activityData: IAuditLog = {} as IAuditLog;
          activityData.dataLog = event.urlAfterRedirects;
          activityData.operationType = "Redirect";
          activityData.tableName = "";
          activityData.dataId = "";

          this._authService.ActivityLog(activityData).subscribe((RespData) => {
            // console.log(RespData);
          });

        }
      }

      if (event instanceof NavigationError) {
        // Hide loading indicator

        // Present error to user
        console.log(event.error);
      }

    });
  }

}
