
import { Injectable } from '@angular/core';

@Injectable()

export class AppConfig {
  private _config: { [key: string]: string };
  constructor() {
    this._config = {
      BASE_URL: 'https://devapi.questplus.in/',
      STORE_BASE_URL: 'https://devapi.questplus.in/',
      //API_BASE_URL: 'https://devapi.questplus.in/'
      API_BASE_URL: 'https://localhost:44352/'
    };
  }

  get setting(): { [key: string]: string } {
    return this._config;
  }

  get(key: any) {
    return this._config[key];
  }
}

export interface IContentDefaultPoints {
  contentTypeCode?: number;
  points?: number;
}
