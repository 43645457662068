import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpResponse,
  HttpContextToken,
} from "@angular/common/http";
import { Observable, of, throwError } from "rxjs";
import { catchError, filter, tap } from "rxjs/operators";
import { Router } from "@angular/router";
import { IAuditLog } from "../models/IResultInfo.model";
import { AuthService } from "../services/auth.service";

export const DataLog = new HttpContextToken<string>(() => '');
@Injectable()
export class HttpIntercept implements HttpInterceptor {
  constructor(public router: Router,
    private _authService: AuthService) { }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let checkforAuth = req.url.split("/");
    let ReqArr: ICacheAPIRequest[] = [];
    let isCache: boolean = false;
    let CacheRequ: ICacheAPIRequest = {} as ICacheAPIRequest;
    let isLogger: boolean = false;

    if (req.params.get("isLogger")) {

      isLogger = (req.params.get("isLogger").toString().toLocaleLowerCase() === 'true') ? true : false;

      if (isLogger) {
        let ActivityLog: IAuditLog = {} as IAuditLog;
        ActivityLog.dataLog = req.context.get(DataLog).toString();
        ActivityLog.dataId = req.params.get("dataId");
        ActivityLog.tableName = req.params.get("moduleName");
        ActivityLog.operationType = req.params.get("eventName");

        this._authService.ActivityLog(ActivityLog).subscribe((RespData) => {
          //console.log(RespData);
        });
      }

    }


    if (req.params.get("cache") && req.params.get("cache") == "true") {
      isCache = true;

      if (localStorage.getItem("CacheStorage")) {
        ReqArr = JSON.parse(localStorage.getItem("CacheStorage"));

        let isExists = ReqArr.filter((x) => x.APIEndpoint == req.url);
        if (isExists && isExists.length > 0) {
          //check request body
          if (
            JSON.stringify(req.body) === JSON.stringify(isExists[0].ReqBody) &&
            isExists[0].ReqResponse
          ) {
            //check time difference
            let seconds =
              Math.abs(new Date().getTime() - isExists[0].FetchOn) / 1000;

            if (seconds < 3600) {
              return of(
                new HttpResponse({
                  body: isExists[0].ReqResponse,
                  status: 200,
                })
              );
            }
          }
        }
      }

      let id = Math.floor(Math.random() * 10000000000);
      let APIEndpoint = req.url;
      req = req.clone({
        url: req.url + "?uid=" + id,
      });

      CacheRequ.APIEndpoint = APIEndpoint;
      CacheRequ.APIKey = req.url;
      CacheRequ.ReqBody = req.body;
      CacheRequ.FetchOn = new Date().getTime();
      CacheRequ.ReqResponse = null;
      CacheRequ.id = id;

      if (ReqArr && ReqArr.length > 0) {
        ReqArr = ReqArr.filter((x) => x.APIEndpoint != CacheRequ.APIEndpoint);
      } else {
        ReqArr = [];
      }

      ReqArr.push(CacheRequ);
      localStorage.setItem("CacheStorage", JSON.stringify(ReqArr));
    }

    return next.handle(req).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          if (event.url) {
            try {
              let params = new URL(event.url).searchParams;

              if (params.get("uid") && Number(params.get("uid")) > 0) {
                let cacheArray: ICacheAPIRequest[] = JSON.parse(
                  localStorage.getItem("CacheStorage")
                );

                let isExists_Res = cacheArray.filter(
                  (x) => x.id == Number(params.get("uid"))
                );

                if (isExists_Res && isExists_Res.length > 0) {
                  cacheArray.forEach((element) => {
                    if (element.id == Number(params.get("uid"))) {
                      element.ReqResponse = event.body;

                      localStorage.setItem(
                        "CacheStorage",
                        JSON.stringify(cacheArray)
                      );
                    }
                  });
                }
              }
            }
            catch (ex) {

            }
          }

        }
      }),
      catchError((error: HttpErrorResponse) => {
        let eobj: any = error.error;
        if (error) {
          if (
            (error.status === 403 || error.status === 401) &&
            checkforAuth[4] == "AuthenticateUser"
          ) {
            //this.snackbar.openSnackBar(eobj.error, '', '', 'bottom', 'center');
          } else if (
            (error.status === 403 || error.status === 401) &&
            checkforAuth[4] !== "AuthenticateUser"
          ) {
            //this._common.sessionTimeout.emit(true);
          } else {
            //this.snackbar.openSnackBar(error.message, '', '', 'bottom', 'center');
          }
          return throwError(error);
        } else {
          return throwError(error);
        }
      })
    );
  }
}

export interface ICacheAPIRequest {
  APIEndpoint?: string;
  APIKey: string;
  ReqBody: any;
  ReqResponse?: any;
  FetchOn?: number;
  id?: number;
}
