<script src="https://vjs.zencdn.net/7.3.0/video.js"></script>
<script src="https://unpkg.com/videojs-playlist@4.3.0/dist/videojs-playlist.min.js"></script>

<div class="videoBg">
  <div class="videoBox">
    <div *ngIf="CurrentSrc.contenttype" class="video"
      [ngClass]="CurrentSrc.contenttype.toLowerCase()=='ebook' ? 'pdfBackground':''">
      <div *ngIf="CurrentSrc.contenttype && CurrentSrc.contenttype.toLowerCase()=='av'" (click)="onPlayPause()"
        style="position: absolute;height: inherit;width: 100%;background-color: transparent;z-index: 99;">
      </div>
      <video *ngIf="CurrentSrc.contenttype.toLowerCase()=='av'" id="my-video" controls class="video-js"
        class="video-js vjs-default-skin" preload="auto" style="height: 100%; width: 100%;">
        <p class="vjs-no-js">
          To view this video please enable JavaScript, and consider upgrading to a web browser that
          <a href="https://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>
        </p>
      </video>
      <iframe *ngIf="CurrentSrc.contenttype && CurrentSrc.contenttype.toLowerCase()=='activity'" #ifrm width="100%"
        height="100%" frameBorder="0" [src]="urlSafe"></iframe>
      <img class="ImgContent" *ngIf="CurrentSrc.contenttype && CurrentSrc.contenttype.toLowerCase()=='image'"
        src="{{CurrentSrc.src}}">
      <pdf-viewer *ngIf="CurrentSrc.contenttype && CurrentSrc.contenttype.toLowerCase()=='ebook'" [src]="CurrentSrc.src"
        [render-text]="true" [show-all]="false" [page]="pdfCurrentPage" [zoom]="pdfZoom" [rotation]="pdfRotate"
        (after-load-complete)="onPDFLoadComplete($event)" (error)="onPDFError($event)"
        (on-progress)="onPDFProgress($event)"></pdf-viewer>

      <app-pageloader *ngIf="showSpinner"></app-pageloader>
    </div>
    <div class="controlsBox">
      <div class="sikbar">
        <div *ngIf="CurrentSrc.contenttype && CurrentSrc.contenttype.toLowerCase()=='av'" class="BottomBtm PlayPauseBtn" (click)="onPlayPause()">
          <img *ngIf="isPlay" src="assets/icon/pause_w.svg" />
          <img *ngIf="!isPlay" src="assets/icon/play_w.svg" />
        </div>
        <div class="BottomBtm" *ngIf="CurrentSrc.contenttype && CurrentSrc.contenttype.toLowerCase()=='ebook'">
          <div class="btn" style="cursor: pointer;" (click)="onPDFNextPrevPage(-1)">
            <img style="width: 40px; transform: rotate(180deg);"
              src="/assets/icon/thick-arrow-left.svg" />
          </div>
          <div>
            <span>{{pdfCurrentPage}}/{{pdfTotalPage}}</span>
          </div>
          <div class="btn" style="cursor: pointer;" (click)="onPDFNextPrevPage(1)">
            <img style="width: 40px;" src="/assets/icon/thick-arrow-left.svg" />
          </div>
        </div>
        <div *ngIf="CurrentSrc.contenttype && CurrentSrc.contenttype.toLowerCase()=='av'" class="BottomBtm rewindBtn" (click)="onReplay();">
          <img src="assets/icon/rewind_w.svg" />
        </div>
        <div *ngIf="CurrentSrc.contenttype && CurrentSrc.contenttype.toLowerCase()=='av'" class="BottomBtm audioBtn" (click)="onAudioClick();">
          <img src="assets/icon/audio_w.svg" />
        </div>
      </div>
      <!-- <div class="controls">
        <ul>
          <li *ngIf="CurrentSrc.contenttype && CurrentSrc.contenttype.toLowerCase()=='av'">
            <div [ngClass]="showAV && !showIframe ? 'btn':'disabledControl'" (click)="onAudioClick();"
              style="cursor: pointer;">
              <img src="/assets/icon/audio_w.svg" />
            </div>
          </li>
          <li *ngIf="CurrentSrc.contenttype && CurrentSrc.contenttype.toLowerCase()=='av'">
            <div [ngClass]="showAV && !showIframe ? 'btn':'disabledControl'" (click)="onReplay();"
              style="cursor: pointer;">
              <img src="/assets/icon/rewind_w.svg" />
            </div>
          </li>
          <li *ngIf="CurrentSrc.contenttype && CurrentSrc.contenttype.toLowerCase()=='av'">
            <div [ngClass]="showAV && !showIframe ? 'btn':'disabledControl'" (click)="onPlayPause()"
              style="cursor: pointer;">
              <img *ngIf="isPlay" src="/assets/icon/pause_w.svg" />
              <img *ngIf="!isPlay" src="/assets/icon/play_w.svg" style="height: 40px;" />
            </div>
          </li>

          <li *ngIf="CurrentSrc.contenttype && CurrentSrc.contenttype.toLowerCase()=='ebook'">
            <div class="btn" style="cursor: pointer;" (click)="onPDFNextPrevPage(-1)">
              <img style="width: 40px; transform: rotate(180deg);"
                src="/assets/icon/thick-arrow-left.svg" />
            </div>
            <div>
              <span style="letter-spacing: 5px;">{{pdfCurrentPage}}/{{pdfTotalPage}}</span>
            </div>
            <div class="btn" style="cursor: pointer;" (click)="onPDFNextPrevPage(1)">
              <img style="width: 40px;" src="/assets/icon/thick-arrow-left.svg" />
            </div>
          </li>
        </ul>
      </div> -->
    </div>
  </div>
  <div class="btn" (click)="OnClose()" style="position: absolute; top: 0px; right: 0px; z-index: 999; cursor: pointer;">
    <img src="assets/icon/cancel-button.svg" />
  </div>
</div>
