import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { ChartType, ChartOptions } from "chart.js";
import { Label } from "ng2-charts";
import * as pluginDataLabels from "chartjs-plugin-datalabels";

@Component({
  selector: "app-report-chart",
  templateUrl: "./report-chart.component.html",
  styleUrls: ["./report-chart.component.scss"],
})
export class ReportChartComponent implements OnChanges {
  @Input() type: string;
  @Input() data: any[];
  @Input() ChartLabels: any[];
  @Input() xAxesLabel: string;
  @Input() yAxesLabel: string;
  @Input() yAxesLabel_right: string;
  @Input() stacked: boolean = true;
  @Input() LegendPosition: string;

  labels: Label[] = [];

  @Output() onObjectClick = new EventEmitter();

  //ChartOptions
  public pieChartOptions: any = {
    responsive: true,
    legend: {
      position: "top",
    },
  };
  public pieChartPlugins = [pluginDataLabels];
  public ChartColors = [
    {
      backgroundColor: [
        "rgba(255,0,0,0.6)",
        "rgba(0,255,0,0.6)",
        "rgba(0,0,255,0.6)",
      ],
    },
  ];
  ChartLegend = true;

  private donutColors = [
    {
      backgroundColor: [
        '#4dccc5',
        '#f38d5d',
        '#fdd',
      ]
    }
  ];

  constructor() { }
  ngOnChanges() {
    this.labels = [];
    if (this.ChartLabels.length > 0) {
      this.ChartLabels.forEach((element) => {
        this.labels.push(element.label);
      });
    }

    if (this.type === "bar") {
      this.pieChartOptions = {
        scales: {
          xAxes: [
            {
              stacked: this.stacked,
              barThickness: 10,
              scaleLabel: {
                display: true,
                labelString: this.xAxesLabel ? this.xAxesLabel : "",
                fontSize: 10,
              },
            },
          ],
          yAxes: [
            {
              stacked: this.stacked,
              barThickness: 10,
              scaleLabel: {
                display: true,
                labelString: this.yAxesLabel ? this.yAxesLabel : "",
                fontSize: 10,
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            display: false,
            align: "center",
            anchor: "center",
          },
          legend: {
            display: false,
          },
        },
      };
    }

    if (this.yAxesLabel_right && this.yAxesLabel_right.length > 0) {
      this.pieChartOptions = {
        scales: {
          xAxes: [
            {
              stacked: this.stacked,
              barThickness: 20,
              scaleLabel: {
                display: true,
                labelString: this.xAxesLabel ? this.xAxesLabel : "",
                fontSize: 14,
              },
            },
          ],
          yAxes: [
            {
              stacked: this.stacked,
              barThickness: 20,
              scaleLabel: {
                display: true,
                labelString: this.yAxesLabel ? this.yAxesLabel : "",
                fontSize: 14,
              },
            },
            {
              id: "RightYAxis",
              stacked: this.stacked,
              barThickness: 20,
              scaleLabel: {
                display: true,
                labelString: this.yAxesLabel_right ? this.yAxesLabel_right : "",
                fontSize: 14,
              },
              position: "right",
            },
          ],
        },
        plugins: {
          datalabels: {
            display: false,
            align: "center",
            anchor: "center",
          },
        },
      };
    }

    if (this.pieChartOptions && this.pieChartOptions.legend) {
      //console.log(this.pieChartOptions);
      this.pieChartOptions = {
        legend: {
          labels: {
            fontSize: 8,
            usePointStyle: true,
          },
        },
      };

      this.pieChartOptions.legend.position = this.LegendPosition
        ? this.LegendPosition
        : "top";
    }
  }

  public chartClicked({
    event,
    active,
  }: {
    event: MouseEvent;
    active: {}[];
  }): void {
    if (active.length > 0) {
      let index: any = active[0];
      let ClickedObject = this.ChartLabels[index._index];
      this.onObjectClick.emit(ClickedObject);
    }
  }
}
