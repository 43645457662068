import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { IResultInfo } from 'src/app/models/IResultInfo.model';
import videojs from 'video.js';

@Component({
  selector: 'app-content-player',
  templateUrl: './content-player.component.html',
  styleUrls: ['./content-player.component.scss']
})
export class ContentPlayerComponent implements OnChanges {

  @Input() SourcesList: IResources[] = [];
  @Output() onBackClick = new EventEmitter();

  isPlay: boolean = false;
  isMute: boolean = false;
  player: videojs.Player;
  prevContent: IResources = {} as IResources;
  nextContent: IResources = {} as IResources;

  // Content Type bool values //

  showIframe: boolean = false;
  showAV: boolean = false;

  CurrentSrc: IResources = {} as IResources;

  //End
  urlSafe: SafeResourceUrl;
  @ViewChild('ifrm') iframe: ElementRef;

  //PDF Controls
  pdfSrc: any = '';
  pdfTotalPage: number = 0;
  pdfCurrentPage: number = 0;
  pdfRotate: number = 0;
  pdfZoom: number = 1;
  //End

  showSpinner: boolean = false;

  public videoJsConfigObj = {
    preload: 'metadata',
    controls: false,
    autoplay: false,
    overrideNative: false,
    techOrder: ['html5'],
    html5: {
      nativeVideoTracks: false,
      nativeAudioTracks: false,
      nativeTextTracks: false,
      hls: {
        withCredentials: false,
        overrideNative: true,
        debug: true
      }
    }
  };

  constructor(public sanitizer: DomSanitizer) { }

  ngOnChanges(): void {
    setTimeout(() => {
      this.OnPageLand();
    }, 100);
  }

  InitVideoPlayer() {
    this.player = videojs('my-video', this.videoJsConfigObj, function onPlayerReady() {
      this.on('ended', function () {
        this.isPlay = false;
      });
      this.controls = true;
    });
  }

  OnPageLand() {
    let CurrentSrc: IResources;
    if (this.SourcesList.filter(x => x.extraBoolValue).length > 0) {
      this.SourcesList.forEach(element => {
        if (element.extraBoolValue) {
          CurrentSrc = element;
          return false;
        }
      });

    } else {
      CurrentSrc = JSON.parse(JSON.stringify(this.player.currentSources()))[0];
    }
    this.OnLoadVideo(CurrentSrc);
  }

  onPlayPause() {
    if (this.showAV && !this.showIframe) {
      if (this.player.paused()) {
        this.player.play();
        this.isPlay = true;
      } else {
        this.player.pause();
        this.isPlay = false;
      }
    }
  }

  OnLoadVideo(content: IResources) {
    if (content.id) {

      this.prevContent = {} as IResources;
      this.nextContent = {} as IResources;

      this.CurrentSrc = {} as IResources;
      this.CurrentSrc = content;
      const CurIndex = this.SourcesList.findIndex(x => x.id === this.CurrentSrc.id);
      if (CurIndex > 0) {
        this.prevContent = this.SourcesList[CurIndex - 1];
      } else {
        this.prevContent = {
          bValue: false
        } as IResources;
      }

      if ((CurIndex + 1) < this.SourcesList.length && CurIndex >= 0) {
        this.nextContent = this.SourcesList[CurIndex + 1];
      } else {
        this.nextContent = {
          bValue: false
        } as IResources;
      }

      if (this.CurrentSrc.contenttype && this.CurrentSrc.contenttype.toLowerCase() === 'av') {
        this.showAV = true;
        this.showIframe = false;

        setTimeout(() => {
          this.InitVideoPlayer();
          this.player.poster(this.CurrentSrc.image);
          this.player.src(JSON.parse(JSON.stringify(this.CurrentSrc)));
          this.isPlay = false;
        }, 100);
        this.showSpinner = false;


      } else if (this.CurrentSrc.contenttype && this.CurrentSrc.contenttype.toLowerCase() === 'activity') {
        if (this.player) {
          this.player.dispose();
        }
        this.isPlay = false;
        this.showAV = false;
        this.showIframe = true;
        this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.CurrentSrc.src);
        this.showSpinner = false;
        // this.pushMessage();
      } else if (this.CurrentSrc.contenttype && this.CurrentSrc.contenttype.toLowerCase() === 'image') {
        if (this.player) {
          this.player.dispose();
        }
        this.isPlay = false;
        this.showAV = false;
        this.showIframe = false;
        this.showSpinner = false;
      } else if (this.CurrentSrc.contenttype && this.CurrentSrc.contenttype.toLowerCase() === 'ebook') {
        this.ResetPdfControls();
        if (this.player) {
          this.player.dispose();
        }
        this.isPlay = false;
        this.showAV = false;
        this.showIframe = false;
        this.showSpinner = true;
      }
    }
  }

  onAudioClick() {
    if (this.showAV && !this.showIframe) {
      this.isMute = !this.isMute;
      this.player.muted(this.isMute);
    }
  }

  onReplay() {
    if (this.showAV && !this.showIframe) {
      this.player.currentTime(0);
    }
  }

  OnClose() {
    this.SourcesList = [];
    if (this.showAV && !this.showIframe) {
      this.player.dispose();
    }
    this.onBackClick.emit(true);
  }

  @HostListener('window:message', ['$event']) onMessage(e) {
    setTimeout(() => {
      this.pushMessage();
    }, 500);
  }

  pushMessage() {
    setTimeout(() => {
      //this.iframe.nativeElement.contentWindow.postMessage('Parent Message Rajesh', '*');
    }, 500);

  }

  ResetPdfControls() {
    this.pdfSrc = '';
    this.pdfTotalPage = 0;
    this.pdfCurrentPage = 0;
    this.pdfRotate = 0;
    this.pdfZoom = 1;
  }

  onPDFLoadComplete(obj: ebookloadcomplete) {
    if (obj && obj._pdfInfo) {
      this.pdfCurrentPage = 1;
      this.pdfTotalPage = obj._pdfInfo.numPages;
    }
  }

  onPDFError(obj) {
    //console.log(obj);
  }

  onPDFProgress(obj: ebookpageload) {
    //console.log(obj);
    if (obj) {
      if (obj.loaded === obj.total) {
        this.showSpinner = false;
      }
    }
  }

  onPDFNextPrevPage(obj: number) {
    // for next page
    if (obj > 0 && this.pdfCurrentPage !== this.pdfTotalPage) {
      this.pdfCurrentPage++;
    }

    // for prev page
    if (obj < 0 && this.pdfCurrentPage !== 1) {
      this.pdfCurrentPage--;
    }
  }

  onPDFZoomInOut(obj: number) {
    // for zoom in
    if (obj > 0 && this.pdfZoom < 2) {
      this.pdfZoom = this.pdfZoom + 0.5;
    }

    // for zoom out
    if (obj < 0 && this.pdfZoom > 1) {
      this.pdfZoom = this.pdfZoom - 0.5;
    }
  }

  onPDFRotate() {
    this.pdfRotate = this.pdfRotate === 360 ? 90 : this.pdfRotate + 90;
  }

}

export interface ebookpageload {
  loaded?: number;
  total?: number;
}

export interface ebookloadcomplete {
  _pdfInfo?: ebookloadsub;
}

export interface ebookloadsub {
  fingerprint?: string;
  numPages?: number;
}

export interface IResources extends IResultInfo {
  id?: any;
  src?: string;
  type?: string;
  contenttype?: string;  //AV, E-Book, Audio,
  image?: string; // Poster Image
  title?: string;
  SequenceNo?: number;
}
