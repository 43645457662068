import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { IUserEntity } from '../models/IUserObject.model';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private _authService: AuthService) { }
  // canActivate(
  //   next: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot
  // ): Observable<boolean> | Promise<boolean> | boolean {
  //   this._authService.verifyToken().subscribe(data => {
  //     if (!data) {
  //       this.router.navigate(['/login']);
  //     }
  //   });
  //   return this._authService.verifyToken();
  // }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {

    let url: string = state.url;
    let IsAccessApproved: boolean;

    let UserProfile: IUserEntity = {} as IUserEntity;
    UserProfile = JSON.parse(localStorage.getItem('AuthUser'));

    if (UserProfile && next.routeConfig.data) {
      let MenuExist = UserProfile.roleAccess.find(x => x.menuId == next.routeConfig.data.menuId);
      if (MenuExist) {
        IsAccessApproved = true;
      }
    }

    if (sessionStorage.getItem("quest_access_token")) {
      // if (IsAccessApproved) {

      // } else {
      //   this.router.navigate(["unauthorized-access"], { replaceUrl: true });
      // }
      return true;
    } else {
      sessionStorage.clear();
      this.router.navigate(["/login"], { replaceUrl: true });
      return false;
    }

  }
}
