import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fullTextSearch',
  pure: false
})
export class FullTextSearchPipe implements PipeTransform {

  constructor() { }

  transform(value: any[], query: any, column?: string): any[] {
    if (column) {
      return query
      ? value.filter(
        obj =>
          Object.keys(obj)
            .map(key => obj[column])
            .toString()
            .toLocaleLowerCase()
            .indexOf(query.toLocaleLowerCase()) !== -1
      )
      : value;
    } else {
      return query
        ? value.filter(
          obj =>
            Object.keys(obj)
              .map(key => obj[key])
              .toString()
              .toLocaleLowerCase()
              .indexOf(query.toLocaleLowerCase()) !== -1
        )
        : value;
    }

  }
}
