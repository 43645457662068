import { Injectable } from '@angular/core';
import { IUserEntity } from '../models/IUserObject.model';
import { IUserMenuAccess } from '../models/IUserMenuAccess.model';
import { IAuditLog } from '../models/IResultInfo.model';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  _AuthUser: IUserEntity = {} as IUserEntity;
  _MenuAccess: IUserMenuAccess = {} as IUserMenuAccess;
  constructor() { }

  getCurrentUser() {
    this._AuthUser = JSON.parse(localStorage.getItem('AuthUser'));
    return this._AuthUser;
  }

  getMenuAccess(url: string) {
    this._MenuAccess = {} as IUserMenuAccess;
    this._AuthUser = JSON.parse(localStorage.getItem('AuthUser'));

    let arr = url.split('/');
    this._AuthUser.roleAccess.forEach(element => {
      if (element.menuUrl === url || element.menuUrl === '/' + arr[0] || element.menuUrl === '/' + arr[1]) {
        this._MenuAccess = element;
      }
    });

    if (url.startsWith('/queststructure/')) {
      this._MenuAccess = this._AuthUser.roleAccess.filter(x => x.menuUrl == '/quest')[0];
    } else if (url.startsWith('/User/')) {
      this._MenuAccess = this._AuthUser.roleAccess.filter(x => x.menuUrl == '/User')[0];
    }

    return this._MenuAccess;
  }

  getRoleDetails() {
    let RoleName: string;
    this._AuthUser = JSON.parse(localStorage.getItem('AuthUser'));
    if (this._AuthUser) {
      this._AuthUser.roleAccess.forEach(element => {
        RoleName = element.roleName;
      });
    }
    return RoleName;
  }


}
