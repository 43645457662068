import { Injectable } from '@angular/core';
import { IUserEntity } from '../models/IUserObject.model';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../core/config/config';
import { SharedService } from './shared.service';
import { Observable } from 'rxjs';
import { IResultInfo } from '../models/IResultInfo.model';
import { IBlobContent } from '../admin/models/IBlobContent.model';

@Injectable({
  providedIn: 'root'
})
export class FilemanagerService {
  private _baseURL = this.config.setting['BASE_URL'];
  _AuthUser: IUserEntity = {} as IUserEntity;

  constructor(
    private http: HttpClient,
    private config: AppConfig,
    private _sharedServie: SharedService) {
  }

  UploadFile(data: FormData): Observable<IResultInfo> {
    this._AuthUser = this._sharedServie.getCurrentUser();
    data.append('UserId', this._AuthUser.userId);
    return this.http.post<IResultInfo>(this._baseURL + 'FileManager/UploadImages', data);
  }

  CreateFolder(FileDirectory: string, FolderName: string): Observable<IResultInfo> {
    return (this.http.get<IResultInfo>(this._baseURL + 'FileManager/CreateNewFolder?FileDirectory=' + FileDirectory + '&FolderName=' + FolderName));
  }

  GetFolderdetails(FileDirectory: string): Observable<IBlobContent[]> {
    return (this.http.get<IBlobContent[]>(this._baseURL + 'FileManager/GetStorageDetails?FileDirectory=' + FileDirectory));
  }
}
