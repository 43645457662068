import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthGuard } from './core/auth.guard';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'filemanager',
    loadChildren: () =>
      import('./file-manager/file-manager.module').then(m => m.FileManagerModule),
    canActivate: [AuthGuard]
  }, {
    path: 'web-store',
    loadChildren: () =>
      import('./web-store/web-store.module').then(m => m.WebStoreModule)
  },
  {
    path: 'reset-password',
    loadChildren: () =>
      import('./reset-password/reset-password.module').then(m => m.ResetPasswordModule)
  }, {
    path: 'online-assessment',
    loadChildren: () => import('./online-assessment/online-assessment.module').then(m => m.OnlineAssessmentModule)
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    relativeLinkResolution: 'legacy', preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
