import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { RouterTabs } from './router-tab/router-tabs.directive';
import { RouterTab } from './router-tab/router-tab.directive';
import { PageloaderComponent } from './pageloader/pageloader.component';
import { NumberDirective } from './directive/numbers-only.directive'


import { ProcessLoaderComponent } from './process-loader/process-loader.component';
import { TextTrim } from './directive/TextTrim.directive';
import { FullTextSearchPipe } from './directive/fulltextsearch.pipe';
import { CkeditorDescriptionComponent } from './ckeditor-description/ckeditor-description.component';
import { ContentPlayerComponent } from './content-player/content-player.component';

import { ReportChartComponent } from './report-chart/report-chart.component';

import { CommonUtilities } from '.';
import { SafeHtmlPipe } from './directive/safehtml.pipe';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material/material.module';
import { ChartsModule } from 'ng2-charts';
import { CountdownConfig, CountdownGlobalConfig, CountdownModule } from 'ngx-countdown';
import {DragDropModule} from '@angular/cdk/drag-drop';
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    ChartsModule,
    CountdownModule,
    DragDropModule
  ],
  declarations: [
    PageNotFoundComponent,
    RouterTabs,
    RouterTab,
    PageloaderComponent,
    NumberDirective,
    ProcessLoaderComponent,
    TextTrim,
    FullTextSearchPipe,
    CkeditorDescriptionComponent,
    ContentPlayerComponent,
    ReportChartComponent,
    SafeHtmlPipe
  ],
  exports: [
    CommonModule,
    FlexLayoutModule,
    PageNotFoundComponent,
    RouterTabs,
    RouterTab,
    PageloaderComponent,
    NumberDirective,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    ProcessLoaderComponent,
    TextTrim,
    FullTextSearchPipe,
    CkeditorDescriptionComponent,
    ContentPlayerComponent,
    ReportChartComponent,
    SafeHtmlPipe,
    CountdownModule,
    DragDropModule
  ],
  providers: [DatePipe, CommonUtilities]
})
export class SharedModule { }
