import { HttpClient, HttpContext, HttpHeaders } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppConfig } from "src/app/core/config/config";
import { IUserEntity } from "src/app/models/IUserObject.model";
import { SharedService } from "src/app/services/shared.service";
import { IClassMaster, ISubClassMaster } from "../models/IClass.model";
import { IDashboardReportRequest } from "../models/IDashboardReportRequest.model";
import { IQuestGroup } from "../models/IGroup.model";
import { IInstitution } from "../models/IInstitution.model";
import { IQuestLocation } from "../models/ILocation.model";
import { IMission, IQuestCategory } from "../models/IMission.model";
import { IProductDetails } from "../models/IProductDetails.model";
import { ICurrencyMaster, IQuest } from "../models/IQuest.model";
import { IEntityResponse, IKnowledgeMapping_ListItems } from "../models/IQuestionDetails_Zl.model";
import { IQuestLabel } from "../models/IQuestlabel.model";
import { IQuestProduct } from "../models/IQuestProduct.model";
import { ISearchRequest } from "../models/ISearchRequest.model";
import { DataLog } from "src/app/core/http-interceptor";
import { IRoleMaster } from "src/app/models/IUserMenuAccess.model";
import { IUsersearchCustom } from "../models/IReportRequest.model";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  private _baseURL = this.config.setting["BASE_URL"];
  _AuthUser: IUserEntity = {} as IUserEntity;

  constructor(
    private http: HttpClient,
    private config: AppConfig,
    private _sharedServie: SharedService
  ) { }

  fecthDashboard: EventEmitter<IDashboardReportRequest> = new EventEmitter<IDashboardReportRequest>();

  GetInstitutionList(obj: ISearchRequest, isLogger = false): Observable<IInstitution[]> {
    this._AuthUser = this._sharedServie.getCurrentUser();
    obj.token = this._AuthUser.extraValue;
    return this.http.post<IInstitution[]>(
      this._baseURL + "Common/GetInstitutionList",
      obj,
      {
        context: new HttpContext().set(DataLog, JSON.stringify(obj)),
        params: {
          isLogger: isLogger,
          moduleName: 'Institute',
          eventName: 'GetInstitutionList',
          dataId: ''
        }
      }
    );
  }

  GetLocationList(obj: ISearchRequest, isLogger = false): Observable<IQuestLocation[]> {
    this._AuthUser = this._sharedServie.getCurrentUser();
    obj.token = this._AuthUser.extraValue;
    return this.http.post<IQuestLocation[]>(
      this._baseURL + "Common/GetLocationList",
      obj,
      {
        context: new HttpContext().set(DataLog, JSON.stringify(obj)),
        params: {
          isLogger: isLogger,
          moduleName: 'Locations',
          eventName: 'GetLocationList',
          dataId: ''
        }
      }
    );
  }

  GetClassList(obj: ISearchRequest): Observable<IClassMaster[]> {
    this._AuthUser = this._sharedServie.getCurrentUser();
    obj.token = this._AuthUser.extraValue;
    return this.http.post<IClassMaster[]>(
      this._baseURL + "Common/GetClassList",
      obj
    );
  }

  GetSubClassList(obj: ISearchRequest): Observable<ISubClassMaster[]> {
    this._AuthUser = this._sharedServie.getCurrentUser();
    obj.token = this._AuthUser.extraValue;
    return this.http.post<ISubClassMaster[]>(
      this._baseURL + "Common/GetSubClassList",
      obj
    );
  }

  GetGroupList(obj: ISearchRequest, isLogger = false): Observable<IQuestGroup[]> {
    this._AuthUser = this._sharedServie.getCurrentUser();
    obj.token = this._AuthUser.extraValue;
    return this.http.post<IQuestGroup[]>(
      this._baseURL + "Common/GetGroupList",
      obj,
      {
        context: new HttpContext().set(DataLog, JSON.stringify(obj)),
        params: {
          isLogger: isLogger,
          moduleName: 'Group',
          eventName: 'GetGroupList',
          dataId: ''
        }
      }
    );
  }

  GetQuestList(obj: ISearchRequest): Observable<IQuest[]> {
    this._AuthUser = this._sharedServie.getCurrentUser();
    obj.token = this._AuthUser.extraValue;
    return this.http.post<IQuest[]>(this._baseURL + "Common/GetQuestList", obj);
  }

  GetOverallMissionList(obj: ISearchRequest): Observable<IMission[]> {
    this._AuthUser = this._sharedServie.getCurrentUser();
    obj.token = this._AuthUser.extraValue;
    return this.http.post<IMission[]>(this._baseURL + "Common/GetOverallMissionList", obj);
  }

  GetMissionList(obj: ISearchRequest): Observable<IMission[]> {
    this._AuthUser = this._sharedServie.getCurrentUser();
    obj.token = this._AuthUser.extraValue;
    return this.http.post<IMission[]>(
      this._baseURL + "Common/GetMissionList",
      obj
    );
  }

  GetQuestCategory(obj: ISearchRequest): Observable<IQuestCategory[]> {
    this._AuthUser = this._sharedServie.getCurrentUser();
    obj.token = this._AuthUser.extraValue;
    return this.http.post<IQuestCategory[]>(
      this._baseURL + "Common/GetQuestCategory",
      obj
    );
  }

  GetCurrencyList(): Observable<ICurrencyMaster[]> {
    return this.http.get<ICurrencyMaster[]>(
      this._baseURL + "Quest/GetCurrencyList"
    );
  }

  GetQuestLabels(): Observable<IQuestLabel[]> {
    return this.http.get<IQuestLabel[]>(
      this._baseURL + "Quest/GetQuestListLabel"
    );
  }

  GetProductList(obj: ISearchRequest): Observable<IQuestProduct[]> {
    this._AuthUser = this._sharedServie.getCurrentUser();
    obj.token = this._AuthUser.extraValue;
    return this.http.post<IQuestProduct[]>(
      this._baseURL + "Common/GetProductList",
      obj
    );
  }

  setToken(token: string) {
    sessionStorage.setItem("quest_access_token", token);
  }

  getToken() {
    return sessionStorage.getItem("quest_access_token");
  }

  removeToken() {
    return sessionStorage.removeItem("quest_access_token");
  }

  getFiles(url: string) {
    // let headers = { headers: new HttpHeaders({ 'Content-Type': 'application/*', 'access-control-allow-origin': '*/*' }) };
    // return (this.http.get(url, headers));
  }

  //Get Km_DataList

  GetDataList(
    flag: number,
    parentid: string
  ): Observable<IKnowledgeMapping_ListItems[]> {
    this._AuthUser = this._sharedServie.getCurrentUser();
    let token = this._AuthUser.extraValue;
    return this.http.get<IKnowledgeMapping_ListItems[]>(
      this._baseURL +
      "Assessment_Question/GetKm_DataList?Flag=" +
      flag +
      "&ParentId=" +
      parentid + "&Token=" + token
    );
  }


  GetRoleMasterList(): Observable<IEntityResponse> {
    this._AuthUser = this._sharedServie.getCurrentUser();
    let token = this._AuthUser.extraValue;
    return this.http.get<IEntityResponse>(this._baseURL + "Common/GetRoleMaster?Token=" + token);
  }

  GetRolInfoById(roleId?: string): Observable<IEntityResponse> {
    this._AuthUser = this._sharedServie.getCurrentUser();
    let token = this._AuthUser.extraValue;
    return this.http.get<IEntityResponse>(this._baseURL + "Common/GetRoleInfoById?RoleId=" + roleId + "&Token=" + token,
      {
        context: new HttpContext().set(DataLog, ''),
        params: {
          isLogger: true,
          moduleName: 'Role',
          eventName: 'Edit Role',
          dataId: roleId
        }
      });
  }

  InsertUpdateRoleMaster(data: IRoleMaster): Observable<IEntityResponse> {
    this._AuthUser = this._sharedServie.getCurrentUser();
    data.extraValue = this._AuthUser.userId;
    return this.http.post<IEntityResponse>(this._baseURL + "Common/InsertUpdateRoleMaster", data,
      {
        context: new HttpContext().set(DataLog, JSON.stringify(data)),
        params: {
          isLogger: true,
          moduleName: 'Role',
          eventName: 'InsertUpdateRoleMaster',
          dataId: ''
        }
      }
    );
  }

  InsertUpdateRoleAccess(data: IRoleMaster): Observable<IEntityResponse> {
    this._AuthUser = this._sharedServie.getCurrentUser();
    data.extraValue = this._AuthUser.userId;
    return this.http.post<IEntityResponse>(this._baseURL + "Common/InsertUpdateRoleAccess", data,
      {
        context: new HttpContext().set(DataLog, JSON.stringify(data)),
        params: {
          isLogger: true,
          moduleName: 'Role',
          eventName: 'InsertUpdateRoleAccess',
          dataId: ''
        }
      }
    );
  }

  GetAllRoleList(): Observable<IEntityResponse> {
    this._AuthUser = this._sharedServie.getCurrentUser();
    let token = this._AuthUser.extraValue;
    return this.http.get<IEntityResponse>(this._baseURL + "Common/GetAllRoleList?Token=" + token);
  }

  GetAllUserListCustom(data: IUsersearchCustom): Observable<IEntityResponse> {
    this._AuthUser = this._sharedServie.getCurrentUser();
    let token = this._AuthUser.extraValue;
    data.extraValue = token;
    return this.http.post<IEntityResponse>(this._baseURL + "Common/GetCustomeUserList", data);
  }

  GetBoardMaster() {
    return this.http.get<IEntityResponse>(this._baseURL + "Common/GetBoardMaster");
  }

  GetGradeMaster(boardId?: string) {
    return this.http.get<IEntityResponse>(this._baseURL + "Common/GetGradeMaster?BoardId=" + boardId);
  }

  GetGradeSubjectMaster() {
    return this.http.get<IEntityResponse>(this._baseURL + "Common/GetSubjectMaster");
  }

}
