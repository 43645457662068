import { Injectable } from '@angular/core';
import { AppConfig } from '../core/config/config';
import { HttpClient, HttpContext } from '@angular/common/http';
import { IUserEntity } from '../models/IUserObject.model';
import { Observable, of } from 'rxjs';
import { IAuditLog, IResultInfo, ITempPasswordObj } from '../models/IResultInfo.model';
import { map } from 'rxjs/operators';
import { IRWObj } from '../reset-password/rwindex/rwindex.component';
import { SharedService } from './shared.service';
import { CommonService } from '../admin/services/common.service';
import { IEntityResponse } from '../admin/models/IQuestionDetails_Zl.model';
import { DataLog } from '../core/http-interceptor';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _baseURL = this.config.setting['BASE_URL'];
  _AuthUser: IUserEntity;

  constructor(private http: HttpClient,
    private config: AppConfig, private _sharedServie: SharedService, private _common: CommonService) { }

  AuthenticateUser(Username: string, password: string): Observable<IUserEntity> {
    let logData = {
      "Username": Username
    }
    return (this.http.get<IUserEntity>(this._baseURL + 'Auth/GetAuthenticateUser?Username=' + Username + '&Password=' + password,
      {
        context: new HttpContext().set(DataLog, JSON.stringify(logData)),
        params:
        {
          isLogger: true,
          moduleName: 'Auth',
          eventName: 'Login',
          dataId: ''
        }
      }
    ));
  }

  verifyToken(): Observable<boolean> {
    this._AuthUser = JSON.parse(localStorage.getItem('AuthUser'));
    if (this._AuthUser) {
      return this.http.get<IResultInfo>(this._baseURL + 'Auth/GetVerifyToken?Token=' + this._AuthUser.extraValue)
        .pipe(map((item: any) => {
          if (item && item.isSuccess) {
            localStorage.setItem('AuthUser', JSON.stringify(this._AuthUser));
          } else {
            localStorage.setItem('AuthUser', JSON.stringify(this._AuthUser));
          }
          return item.isSuccess;
        }));
    } else {
      return of(false);
    }
  }

  logout(): Observable<boolean> {
    this._AuthUser = JSON.parse(localStorage.getItem('AuthUser'));
    if (this._AuthUser) {
      return this.http.get<IResultInfo>(this._baseURL + 'Auth/Logout?Token=' + this._AuthUser.extraValue,
        {
          context: new HttpContext().set(DataLog, ""),
          params:
          {
            isLogger: true,
            moduleName: 'Auth',
            eventName: 'Logout',
            dataId: ''
          }
        })
        .pipe(map((item: any) => {
          if (item && item.isSuccess) {
            //localStorage.clear();
          } else {
            return of(false);
          }
          return item.isSuccess;
        }));
    } else {
      return of(false);
    }
  }

  VerifyFGURL(encyptvalue: string): Observable<IResultInfo> {
    return (this.http.get<IResultInfo>(this._baseURL + 'Auth/VerifyForgotPassURL?EncryptedValue=' + encyptvalue));
  }

  ResetPassword(passObj: IRWObj): Observable<IResultInfo> {
    return (this.http.post<IResultInfo>(this._baseURL + 'Auth/CreateNewUserPassword', passObj));
  }

  getUserProfile(): Observable<IUserEntity> {
    let token = this._common.getToken();
    return (this.http.get<IUserEntity>(this._baseURL + 'Auth/GetUserInfo?Token=' + token));
  }

  ForgotPasswordRequest(username: string): Observable<IResultInfo> {
    return (this.http.get<IResultInfo>(this._baseURL + "User/ForgotPasswordRequest?Username=" + username));
  }

  ChangePassword(data: ITempPasswordObj): Observable<IEntityResponse> {
    let token = this._common.getToken();
    data.token = token;
    return (this.http.post<IEntityResponse>(this._baseURL + "User/ChangePassword", data));
  }

  ActivityLog(data: IAuditLog) {
    this._AuthUser = this._sharedServie.getCurrentUser();
    if (this._AuthUser) {
      data.userId = this._AuthUser.userId;
    }

    return (this.http.post<IEntityResponse>(this._baseURL + "Common/InsertUpdateAuditLog", data));
  }
}
